var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"custom-nav",staticStyle:{"height":"75px"}},[_c('div',{staticClass:"d-flex px-0 px-sm-15 px-md-15",staticStyle:{"height":"75px","align-items":"center"}},[(_vm.isMobile)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-avatar',{attrs:{"size":"120","tile":""},on:{"click":function($event){_vm.$router.push({ name: 'home' }).catch(function (err) { return err; })}}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"contain":"","src":require("@/assets/img/logo_stadio.svg"),"lazy-src":require("@/assets/img/logo_stadio.svg")},on:{"mouseover":function($event){_vm.megaMenu = false}}})],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"px-5",staticStyle:{"color":"white","cursor":"default"},on:{"mouseover":function($event){_vm.megaMenu = false}}},[_vm._v(" . ")]):_vm._e(),(!_vm.isMobile)?_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',{style:(hover ? 'cursor: pointer; color: #6E30E8' : 'cursor: pointer;'),on:{"mouseover":function($event){_vm.megaMenu = true}}},[_vm._v(" PRODUCTOS ")])]}}],null,false,2607267333)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',{staticClass:"text-capitalize px-10",style:(hover ? 'cursor: pointer; color: #6E30E8' : 'cursor: pointer;'),on:{"mouseover":function($event){_vm.megaMenu = false},"click":_vm.goToContact}},[_vm._v(" CONTACTO ")])]}}],null,false,3394249725)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',{staticClass:"text-capitalize px-0",style:(hover ? 'cursor: pointer; color: #6E30E8' : 'cursor: pointer;'),on:{"mouseover":function($event){_vm.megaMenu = false},"click":function($event){_vm.$router.push({ name: 'WhoAreWE' }).catch(function (err) { return err; })}}},[_vm._v(" QUIÉNES SOMOS ")])]}}],null,false,100194348)})],1):_vm._e(),_c('v-spacer'),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"},on:{"click":_vm.activeSearch}},[_vm._v(" mdi-magnify ")]),(_vm.isAuth)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 ml-2 elevation-0",attrs:{"color":"#E9E9E9","icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'profile' })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-account-circle-outline ")])],1)]}}],null,false,368940886)},[_c('span',[_vm._v("Perfil")])])],1):_vm._e(),_c('cart-component',{attrs:{"productCartState":_vm.productCartState}}),(!_vm.isMobile && !_vm.isAuth)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":""},on:{"click":function($event){return _vm.HandlerRouter('login')}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-outline")]),_vm._v(" ingresar / Registrarme ")],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAuth)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"black"},on:{"click":_vm.HandlerLogout}},'v-icon',attrs,false),on),[_vm._v(" mdi-exit-to-app ")]):_vm._e()]}}])},[_c('span',[_vm._v("Salir")])]),(_vm.isMobile && !_vm.isAuth)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_c('v-icon',{},[_vm._v("mdi-account")])],1):_vm._e()],1),(_vm.megaMenu)?_c('mega-menu-component',{attrs:{"categories":_vm.categories},on:{"close:change":_vm.closeMegaMenu}}):_vm._e()],1),_c('v-navigation-drawer',_vm._g(_vm._b({attrs:{"bottom":"","color":"transparent","fixed":"","height":"auto","overlay-color":"secondary","overlay-opacity":".6","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),_vm.$listeners),[_c('v-list',{attrs:{"color":"white","shaped":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.drawerItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[(item.func == true)?_c('v-btn',{staticClass:"font-medium text-capitalize",attrs:{"color":"#2696A6","text":""},on:{"click":function($event){return _vm.HandlerRoute(item.href, item.value)}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(item.func == false)?_c('v-btn',{staticClass:"font-medium text-capitalize",attrs:{"color":"#2696A6","text":""},on:{"click":function($event){return _vm.HandlerRoute(item.href, item.value)}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }