<template>
  <div>
    <div
      :style="
        sticky
          ? 'background-color: #ffffff; height: auto'
          : 'background-color: #ffffff; height: auto'
      "
      :class="sticky ? 'header-sticky' : ''"
      v-scroll="onScroll"
    >
      <div style="background-color: #c9cdd9; height: 0px"></div>
      <v-tabs
        class="d-flex justify-center"
        color="#797A7E"
        background-color="#FFFFFF"
        slider-color="#6E30E8"
      >
        <v-tab @click="$vuetify.goTo('#product')">
          {{ dataProduct.keywords }}
        </v-tab>
        <v-tab @click="$vuetify.goTo('#detalle_producto')">Descripción</v-tab>
        <v-tab @click="$vuetify.goTo('#especificaciones')">
          Especificaciones
        </v-tab>
        <v-tab
          @click="HandlerDowloadManual"
          v-if="
            dataProduct.product != null &&
              dataProduct.product.product_manual !== null
          "
        >
          <span v-if="!loadingManual">MANUAL DE USUARIO</span>
          <span v-else>...</span>
        </v-tab>
      </v-tabs>
    </div>
    <v-sheet color="#D8D6C9" id="product">
      <div class="py-15" v-if="sticky"></div>
      <v-container
        :fluid="
          $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'
            ? false
            : true
        "
      >
        <v-row justify="center" class="mt-10">
          <v-col cols="12" sm="6" md="5">
            <v-img
              :src="dataProduct.images[0]"
              :lazy-src="dataProduct.images[0]"
              contain
              v-if="dataProduct.images != null"
              height="460px"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-skeleton-loader
              v-else
              class="mx-auto"
              type="image"
            ></v-skeleton-loader>
            <div
              class="py-15"
              v-if="!$vuetify.breakpoint.smAndDown && !messageProductAdd"
            ></div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="5"
            class="ml-md-15 d-flex flex-column"
            v-if="Object.keys(dataProduct).length"
          >
            <div>
              <div class="title-font text-uppercase">
                {{ dataProduct.keywords }}
              </div>
              <div class="mt-5 text-17">
                <span
                  style="line-height: 28px"
                  v-html="dataProduct.resume"
                ></span>
              </div>
            </div>
            <div class="mt-auto">
              <prices-detail-component
                :prices="dataProduct.price"
                :isAuth="isAuth"
                :show_full_prices="displayFullPrices"
              />

              <div class="details-box">
                <availability-list :dataProduct="dataProduct" />
              </div>

              <div class="d-flex justify-space-between mt-10">
                <cp-information
                  style="font-size: 18px"
                  v-if="dataProduct"
                  :dataProduct="dataProduct"
                  :authUser="authUser"
                  class="mt-auto"
                />
              </div>
              <div
                v-if="validateStock() && validateUmbral()"
                class="d-flex align-center justify-start py-1"
              >
                <span class="mx-0 text-uppercase" style="font-weight: 500">
                  Cantidad
                </span>
                <span class="ml-1">
                  <v-avatar size="35">
                    <v-btn
                      @click="HandlerQuantity('minus')"
                      color="white"
                      fab
                      x-small
                      class="mx-2 mx-md-0 elevation-0"
                    >
                      <v-icon color="black">mdi-minus</v-icon>
                    </v-btn>
                  </v-avatar>
                  <span class="mx-2">
                    {{ quantity }}
                  </span>
                  <v-avatar @click="HandlerQuantity('plus')" size="35">
                    <v-btn
                      color="white"
                      fab
                      x-small
                      class="mx-0 mx-md-0 elevation-0"
                    >
                      <v-icon color="black">mdi-plus</v-icon>
                    </v-btn>
                  </v-avatar>
                </span>
              </div>

              <div
                class="d-md-flex justify-space-between mt-5"
                style="width: 80%"
                v-if="validateStock() && validateUmbral()"
              >
                <v-btn
                  :loading="loadingAddCart"
                  rounded
                  dark
                  color="#6E30E8"
                  class="mt-1 mt-md-0 font-weight-bold border-width"
                  outlined
                  @click="HandlerAddCart()"
                >
                  Agregar a mi compra
                </v-btn>

                <div class="d-flex mt-2 mt-md-0">
                  <v-btn
                    style="border-width: medium"
                    fab
                    color="#5A5953"
                    x-small
                  >
                    <v-icon color="white">mdi-share-variant-outline</v-icon>
                  </v-btn>
                  <span
                    class="mt-2 ml-1"
                    style="font-size: 12px; font-weight: 600"
                  >
                    COMPARTIR
                  </span>
                </div>
              </div>
            </div>
            <div
              class="py-15"
              v-if="!$vuetify.breakpoint.smAndDown && !messageProductAdd"
            ></div>
            <div
              v-if="$vuetify.breakpoint.smAndDown"
              class="py-md-15 py-10"
            ></div>
          </v-col>
          <v-col cols="12" sm="6" md="5" class="ml-md-15" v-else>
            <v-skeleton-loader
              class="mx-auto"
              type="paragraph"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              type="paragraph"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              type="paragraph"
            ></v-skeleton-loader>
            <br />
            <br />
            <v-skeleton-loader
              class="mx-auto"
              type="paragraph"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
          <v-col offset-md="6" md="6" v-if="messageProductAdd">
            <div class="mb-15 d-flex ml-7">
              <p
                style="color: #6E30E8; cursor: pointer"
                @click="$router.push({ name: 'cart' })"
              >
                Agregaste {{ dataProduct.keywords }} x {{ quantity }}. Ir a
                lista de compra.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-container>
      <v-row class="d-flex" justify="center">
        <v-card
          width="150px"
          height="auto"
          v-for="(item, index) in dataProduct.images"
          :key="index"
          class="mt-n15 mt-sm-n10 mt-md-n15 mr-3"
          v-show="index != 0"
          @click="
            () => {
              showImageBackground = true;
              imageBackground = item;
            }
          "
        >
          <v-hover v-slot="{ hover }">
            <v-img
              contain
              :src="item"
              :lazy-src="item"
              :class="hover ? 'elevation-15' : ''"
              :style="
                hover
                  ? 'cursor: pointer; opacity: 0.8; background-color: black; z-index:10;'
                  : ''
              "
            >
              <v-row
                v-if="hover"
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-btn fab color="white" small>
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </v-row>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-hover>
        </v-card>
      </v-row>
    </v-container>
    <div class="py-5"></div>
    <v-dialog v-model="showImageBackground" width="600">
      <v-card width="600" height="auto">
        <v-img
          style="width: 100%; height: auto"
          :src="imageBackground"
          :lazy-src="imageBackground"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-dialog>
    <v-sheet>
      <v-container>
        <h2 id="detalle_producto">Descripción</h2>
        <div
          v-html="dataProduct.description"
          class="description-container"
        ></div>
      </v-container>
      <v-sheet color="#ECEBE5">
        <v-container>
          <h2 id="especificaciones">Especificaciones</h2>
          <div v-html="dataProduct.specs"></div>
        </v-container>
      </v-sheet>
    </v-sheet>
    <!-- <suscribe-component /> -->
  </div>
</template>

<script>
import SuscribeComponent from "@/components/Utils/suscribe_component";
import informationCP from "@/components/Utils/informationCP";
import { isValidUmbral } from "@/utils/validateUmbral.js";
import PricesDetail from "./utilsComponents/products/ProductDetailsPrices";
import AvailabilityList from "./utilsComponents/products/AvailabilityList.vue";

export default {
  components: {
    "suscribe-component": SuscribeComponent,
    "cp-information": informationCP,
    "prices-detail-component": PricesDetail,
    "availability-list": AvailabilityList
  },
  data() {
    return {
      dataProduct: {},
      displayFullPrices: false,
      quantity: 1,
      productCart: [],
      colorHeart: "black",
      iconHeart: "mdi-heart-outlined",
      id: "",
      action: 1,
      messageProductAdd: false,
      //loading
      loading: false,
      // Imagen
      showImageBackground: false,
      imageBackground: "",
      //Navbar
      sticky: false,
      fab: false,
      loadingManual: false
    };
  },

  async created() {
    this.HandlerGetProducts();

    if (this.isAuth) {
      this.HandlerGetCartsProducts();
      const responseChazki = await this.$store.dispatch(
        "products/CHAZKI_VALIDATE"
      );
      this.responseChazki = responseChazki.data.data;
    }
  },

  computed: {
    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    authUser() {
      return this.$store.getters["auth/GET_PROFILE"];
    },

    productCartState() {
      return this.$store.getters["cart/CART_PRODUCTS"];
    },

    breakpoint() {
      return this.$vuetify.breakpoint.name;
    }
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          // currency: "ARS",
          // style: "currency",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    },

    currency(value) {
      if (value) {
        // es-AR
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          // currency: "USD",
          // style: "currency",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  },

  methods: {
    HandlerGetProducts(id) {
      if (this.isAuth === false) this.HandlerGetProductsNoAuth(id);
      if (this.isAuth == true) this.HandlerGetAuthProducts(id);
    },

    async HandlerGetProductsNoAuth() {
      try {
        this.loadingResponse = true;
        const { brand, keywords } = this.$route.params;

        const response = await this.$store.dispatch(
          "products/GET_PUBLICATION_DETAILS",
          {
            product_brand: brand,
            publication_title: keywords.replaceAll("-", " ")
          }
        );
        this.dataProduct = response.data.data;
        this.displayFullPrices = response.data.display_full_prices;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingResponse = false;
      }
    },

    async HandlerGetAuthProducts() {
      try {
        this.loadingResponse = true;
        const { brand, keywords } = this.$route.params;

        const response = await this.$store.dispatch(
          "products/GET_PUBLICATION_DETAILS",
          {
            product_brand: brand,
            publication_title: keywords.replaceAll("-", " ")
          }
        );

        this.dataProduct = response.data.data;
        this.displayFullPrices = response.data.display_full_prices;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingResponse = false;
      }
    },

    async HandlerGetCartsProducts() {
      try {
        await this.$store.dispatch("cart/GET_CURRENT_CART");
      } catch (error) {
        console.log(error);
      }
    },

    HandlerQuantity(quantity) {
      const productWarehouse = { ...this.dataProduct };
      const userZipCode =
        this.authUserData?.zipcode ?? localStorage.getItem("zipcode");
      const paylod = { zipCode: userZipCode, dataProduct: productWarehouse };

      const threshold = isValidUmbral(paylod);

      if (this.quantity == 1 && quantity == "minus") {
        return;
      } else if (
        quantity == "plus" &&
        this.quantity < threshold &&
        this.quantity < 4
      ) {
        this.quantity++;
      } else if (quantity == "minus") {
        this.quantity--;
      }
    },

    HandlerShowFavoriteIcon(product) {
      if (product == null) {
        this.colorHeart = "black";
        this.iconHeart = "mdi-heart-outline";
        return "mdi-heart-outline";
      } else if (product != null && product.status == true) {
        this.colorHeart = "red";
        this.iconHeart = "mdi-heart";
        return "mdi-heart";
      } else {
        this.colorHeart = "black";
        this.iconHeart = "mdi-heart-outline";
        return "mdi-heart-outline";
      }
    },

    async HandlerAddCart() {
      try {
        if (!this.isAuth) {
          this.$router.push({ name: "login" });
          return;
        }

        this.loadingAddCart = true;
        const cart = this.productCartState;

        const existingProduct = cart.shopping_cart_items.find(
          p => this.dataProduct.id === p.publication_id
        );

        if (existingProduct) {
          existingProduct.original_quantity =
            existingProduct.original_quantity + this.quantity;

          const indexUpdate = cart.shopping_cart_items.findIndex(
            p => this.dataProduct.id === p.publication_id
          );

          cart.shopping_cart_items[indexUpdate] = { ...existingProduct };

          const request = cart.shopping_cart_items.map(prod => {
            return {
              publication_id: prod.publication_id,
              quantity: prod.original_quantity
            };
          });
          await this.$store.dispatch("cart/CREATE_CART", {
            items: [...request]
          });
        } else {
          const oldItem = cart.shopping_cart_items.map(prod => {
            return {
              publication_id: prod.publication_id,
              quantity: prod.original_quantity
            };
          });
          const newitem = [
            ...oldItem,
            {
              publication_id: this.dataProduct.id,
              quantity: this.quantity
            }
          ];

          const itemFilter = newitem.filter(value => {
            if (value.publication_id != undefined) {
              return value;
            }
          });

          this.$store.commit("cart/ADD_ITEM", {
            ...this.dataProduct,
            original_quantity: this.quantity
          });

          await this.$store.dispatch("cart/CREATE_CART", {
            items: [...itemFilter]
          });

          await this.$store.dispatch("cart/GET_CURRENT_CART");
        }
        this.messageProductAdd = true;
      } catch (error) {
        const errorMessage = error?.response?.data?.errors[0]?.message;
        if (errorMessage === "range validation failed on quantity") {
          this.$snotify.error(
            "No se puede agregar más unidades de este producto al carrito.",
            "Error"
          );
        } else {
          this.$snotify.error(
            "Ha ocurrido un error agregando el producto.",
            "Error"
          );
        }
      } finally {
        this.loadingAddCart = false;
      }
    },

    async HandlerBuy() {
      try {
        if (!this.isAuth) {
          this.$router.push({ name: "login" });
          return;
        }
        const request = { update_items: true };

        const response = await this.$store.dispatch(
          "cart/CONFIRM_PRODUCTS_CART",
          request
        );

        const productId = this.dataProduct.product_id;
        // AGREGAR PRODUCTO AL CARRITO E IR A COMPRAR
        const searchProduct = response.data.data.shopping_cart.shopping_cart_items.find(
          product => product.publication.product_id == productId
        );
        // MANDAR AL CHECKOUT PARA PAGAR
        if (searchProduct) {
          this.$router.push({ name: "cart" });
        } else {
          // AGREGAR E IR A CHECKOUT
          this.HandlerAddCart();
          setTimeout(() => {
            this.$router.push({ name: "cart" });
          }, 500);
        }
      } catch (error) {
        console.log(error);
      }
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 50;
      if (top > 50) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    },

    validateStock() {
      return (
        this.dataProduct.product.product_warehouse.some(
          ele => ele.current_stock !== 0
        ) && !this.dataProduct.store.out_stock
      );
    },

    validateUmbral() {
      const userZipCode =
        this.authUserData?.zipcode ?? localStorage.getItem("zipcode");

      const dataProductValue = { ...this.dataProduct };
      const paylod = { zipCode: userZipCode, dataProduct: dataProductValue };

      return isValidUmbral(paylod) > 0 ? true : false;
    }
  }
};
</script>

<style scoped>
.title-font {
  font-size: 2.5em;
  font-weight: 600;
}

.header-sticky {
  position: fixed;
  width: 100%;
  /* top: 0; */
  z-index: 1;
  margin-bottom: 50px !important;
}

.description-container,
.description-container * {
  font-family: "Poppins" !important;
}

.v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow-y: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
    0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}

.text-20 {
  font-size: 1.5em;
}

.text-17 {
  font-size: 17px !important;
}

.in {
  line-height: 25px in !important;
}

.border-width {
  border-width: medium;
}
.details-box .availableBox {
  margin-top: 2px !important;
  margin-bottom: -10px !important;
  display: flex;
  align-items: start;
  justify-content: start;
}
</style>
