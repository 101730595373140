<template>
  <div :class="imgBackground">
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <div class="community">
        <v-container>
          <v-row justify="center" class="mt-md-0">
            <v-col cols="12" md="5" xl="4">
              <h3 class="white--text font-weight-bold mb-0">
                SÉ PARTE DE NUESTRA COMUNIDAD
              </h3>
              <p class="my-0 white--text text-community">
                Mantenete informado sobre nuevos lanzamientos, ofertas,
                promociones y mucho mas
              </p>
            </v-col>
            <v-col col="12" md="4" xl="2">
              <ValidationProvider
                name="correo"
                rules="email|required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="email"
                  solo
                  inverted
                  dense
                ></v-text-field>
                <div
                  v-show="errors.length"
                  class="white--text text-center my-0 py-0"
                  style="font-size: 0.9em"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
              <v-btn
                @click="passes(HandlerSuscribe)"
                block
                color="black"
                class="white--text"
                rounded
                large
              >
                SUSCRIBIRME
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <div class="text-follow mr-6 social-box">SEGUINOS</div>
            <div class="d-flex social-box social-row">
              <v-avatar
                @click="goTo('https://www.facebook.com/stadioargentina/')"
                style="cursor: pointer"
              >
                <img src="../../assets/footer/FACEBOOK.svg" alt="FB" />
              </v-avatar>
              <v-avatar
                class="mx-2"
                @click="goTo('https://www.instagram.com/stadio.argentina/')"
                style="cursor: pointer"
              >
                <img src="../../assets/footer/INSTAGRAM.svg" alt="INS" />
              </v-avatar>
              <v-avatar>
                <img src="../../assets/footer/YOUTUBE.svg" alt="YB" />
              </v-avatar>
            </div>
          </v-row>
        </v-container>
      </div>
    </ValidationObserver>

    <div class="social">
      <v-container>
        <v-row class="d-flex justify-md-space-around">
          <v-col cols="6" sm="6" md="3">
            <img src="../../assets/footer/STADIO.svg" alt="" />
            <div
              class="text-privacy mt-n2 mt-md-n8 ml-md-5"
              @click="showTerms = true"
            >
              Política de privacidad - Términos de uso
            </div>
            <div class="text-privacy ml-md-5">
              © STADIO {{ currentYear }} Todos los derechos reservados
            </div>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="3"
            class="d-flex align-start align-md-center mt-5 mt-md-0 flex-column"
            style="justify-content: center !important;"
          >
            <img width="100%" src="../../assets/footer/CACE.svg" alt="" />
            <v-btn
              block
              color="#6E30E8"
              class="white--text repetance-btn1"
              rounded
              large
              style="max-height: 44px;"
              @click="dialog = true"
            >
              Botón de arrepentimiento >>
            </v-btn>
            <v-dialog v-model="dialog" scrollable width="650">
              <v-card>
                <v-card-title
                  class="d-flex flex-row"
                  style="justify-content:space-between;"
                >
                  <div style="width:100px; height: 30px">
                    <v-img
                      contain
                      src="@/assets/img/logo_stadio.svg"
                      lazy-src="@/assets/img/logo_stadio.svg"
                    ></v-img>
                  </div>
                  <div @click="dialog = false" role="button">
                    <v-icon>mdi-close</v-icon>
                  </div>
                </v-card-title>
                <v-card-text>
                  <div class="modal-title">Quiero cancelar mi pedido:</div>
                  <div class="small-text" style="margin-bottom:8px">
                    Tenés hasta 10 días de corridos desde que lo compraste y/o
                    recibiste para realizar la devolución. El producto debe
                    estar tal cual lo recibiste, en perfectas condiciones,
                    nuevo, sin muestras de uso y con sus embalajes originales
                    incluyendo todos sus accesorios y manuales.
                  </div>
                  <div class="modal-subtitle" v-if="!showNotification">
                    Para procesar la solicitud, por favor, completa el siguiente
                    formulario y nuestro servicio de atención al cliente te
                    proporcionará la información necesaria.
                  </div>
                  <div v-if="showNotification">
                    <div class="wrapper">
                      <svg
                        class="checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                      >
                        <circle
                          class="checkmark__circle"
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        />
                        <path
                          class="checkmark__check"
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </svg>
                    </div>
                    <div class="success-text">
                      Tu solicitud ha sido registrada con éxito, dentro del
                      plazo se 24hrs hábiles nos pondremos en contacto por
                      e-mail para confirmar la gestión.
                    </div>
                  </div>
                  <div class="form-box rounded" v-if="!showNotification">
                    <ValidationObserver ref="obs" v-slot="{ passes }">
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          style="padding:0px 8px !important"
                        >
                          <label for="repentanceName" class="label-text"
                            >Nombre:</label
                          >
                          <ValidationProvider
                            name="Nombre"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="repentanceName"
                              color="#6E30E8"
                              dense
                              filled
                              placeholder="Nombre"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          style="padding:0px 8px !important"
                        >
                          <label for="repentanceLastname" class="label-text"
                            >Apellido:</label
                          >
                          <ValidationProvider
                            name="Apellido"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="repentanceLastname"
                              color="#6E30E8"
                              dense
                              filled
                              placeholder="Apellido"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          style="padding:0px 8px !important; margin-top: -10px;"
                        >
                          <label for="repentanceDni" class="label-text"
                            >Dni:</label
                          >
                          <ValidationProvider
                            name="Dni"
                            rules="required|numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="repentanceDni"
                              color="#6E30E8"
                              dense
                              filled
                              placeholder="12345678"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          style="padding:0px 8px !important; margin-top: -10px;"
                        >
                          <label for="repentancePhone" class="label-text"
                            >Teléfono:</label
                          >
                          <ValidationProvider
                            name="Teléfono"
                            rules="required|numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="repentancePhone"
                              color="#6E30E8"
                              dense
                              filled
                              placeholder="12345678"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                          style="padding:0px 8px !important; margin-top: -10px;"
                        >
                          <label for="repentanceEmail" class="label-text"
                            >Email</label
                          >
                          <ValidationProvider
                            name="Email"
                            rules="email|required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="repentanceEmail"
                              color="#6E30E8"
                              dense
                              filled
                              placeholder="stadio@mail.com"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                          style="padding:0px 8px !important; margin-top: -10px;"
                        >
                          <label for="repentanceProduct" class="label-text"
                            >Producto</label
                          >
                          <ValidationProvider
                            name="Producto"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="repentanceProduct"
                              color="#6E30E8"
                              dense
                              filled
                              placeholder="Producto"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                          style="padding:0px 8px !important; margin-top: -10px;"
                        >
                          <ValidationProvider
                            name="Motivo"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="repentanceReason" class="label-text"
                              >¿Por qué desea cancelar su pedido?</label
                            >
                            <v-select
                              :items="reasons"
                              item-text="title"
                              item-value="value"
                              v-model="repentanceReason"
                              color="#6E30E8"
                              filled
                              dense
                              flat
                              :error-messages="errors"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                          v-if="repentanceReason == 'Otro'"
                          style="padding:0px 8px !important; margin-top: -10px;"
                        >
                          <ValidationProvider
                            name=" "
                            rules=""
                            v-slot="{ errors }"
                          >
                            <v-textarea
                              filled
                              rounded
                              class="rounded"
                              dense
                              placeholder="Dar un motivo para la solicitud de cancelación"
                              color="#6E30E8"
                              v-model="otherReason"
                              :error-messages="errors"
                            ></v-textarea>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-btn
                            rounded
                            elevation="0"
                            large
                            block
                            dark
                            color="#6E30E8"
                            class="text-capitalize"
                            :loading="loadingRegret"
                            @click="passes(HandlerRepentance)"
                          >
                            Confirmar cancelación >>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </ValidationObserver>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" class="repetance-btn2">
            <v-btn
              color="#6E30E8"
              class="white--text regret-button"
              rounded
              large
              @click="dialog = true"
            >
              Botón de arrepentimiento >>
            </v-btn>
          </v-col>
          <v-col cols="9" sm="8" md="3" lg="3" class="d-flex align-center">
            <div class="d-flex align-center mt-2">
              <span class="text-secretary text-end mr-2 mr-md-5 white--text">
                Cumplimos con la Resolución 270/2020 de la Secretaría de
                Comercio Interior del Ministerio de Desarrollo Productivo sobre
                la protección del Consumidor en el Comercio Electrónico
              </span>
              <a
                href="http://qr.afip.gob.ar/?qr=5n7kOdfOgUiazvIPvWJ9dg,,"
                target="_F960AFIPInfo"
                ><img
                  width="90px"
                  height="123px"
                  src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                  border="0"
                  alt=""
              /></a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      loadingRegret: false,
      email: "",
      showError: false,
      dialog: false,
      repentanceName: "",
      repentanceLastname: "",
      repentanceDni: null,
      repentancePhone: null,
      repentanceEmail: "",
      repentanceProduct: "",
      repentanceReason: "",
      showNotification: false,
      otherReason: "",
      reasons: [
        {
          title: "No quiero más este producto",
          value: "No quiero más este producto"
        },
        { title: "Compré por error", value: "Compré por error" },
        {
          title: "La entrega tardará demasiado",
          value: "La entrega tardará demasiado"
        },
        {
          title: "He descubierto un mejor precio en otro lugar",
          value: "He descubierto un mejor precio en otro lugar"
        },
        { title: "Prefiero no decir", value: "Prefiero no decir" },
        { title: "Otro", value: "Otro" }
      ]
    };
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.showNotification = false;
        this.repentanceName = "";
        this.repentanceLastname = "";
        this.repentanceDni = null;
        this.repentancePhone = null;
        this.repentanceEmail = "";
        this.repentanceProduct = "";
        this.repentanceReason = "";
      }
    }
  },

  computed: {
    currentYear: () => {
      return moment().year();
    },
    imgBackground() {
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      ) {
        return "";
      }

      return "img-background";
    }
  },

  methods: {
    async HandlerSuscribe() {
      try {
        this.loading = true;
        const request = {
          email: this.email,
          store_id: 1
        };
        await this.$store.dispatch("products/SUSCRIBE_EMAIL", request);
        this.email = "";
        this.$refs.obs.reset();
        this.$snotify.success("Solicitud enviada", "Exitos!");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    goTo(red) {
      window.open(red);
    },

    async HandlerRepentance() {
      try {
        this.loadingRegret = true;
        const payload = {
          store_id: 1,
          first_name: this.repentanceName,
          last_name: this.repentanceLastname,
          dni: this.repentanceDni,
          phone: this.repentancePhone,
          email: this.repentanceEmail,
          product_name: this.repentanceProduct,
          cancellation_reason: this.repentanceReason
        };

        await this.$store.dispatch("products/SEND_RETURN_FORM", payload);
        this.showNotification = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingRegret = false;
      }
    }
  }
};
</script>

<style>
.img-background {
  background-image: url("../../../src/assets/footer/1920_ presonus-eris-e5-bt-lifestyle-04.png");
  width: 100%;
  max-height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.community {
  background-color: #6e30e8;
  height: auto;
  opacity: 0.9;
}

.text-community {
  font-size: 1.2em;
}

.text-follow {
  color: white;
  font-size: 1.2em;
  font-weight: 600;
}

.social {
  background-color: #16131aa6;
  /* height: auto; */
}

/* @media (min-width: 700px) {
  .social {
    background-color: #16131aa6;
    height: 56.5%;
  }
} */

.text-secretary {
  font-weight: 300;
}

.text-privacy {
  font-size: 1em;
  color: white;
  font-weight: 300;
  cursor: pointer;
}

.vignette {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #6e30e8;
  margin-top: 9px;
  margin-right: 4px;
}

.text-box {
  display: flex;
  flex: 1;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.modal-subtitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.small-text {
  font-size: 13px;
}

.form-box {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 2rem;
}

.label-text {
  font-size: 14px;
}
.v-messages__message {
  font-size: 11px !important;
}

.success-text {
  color: #09a06e;
  font-size: 500;
  text-align: center;
  padding: 0px 20px;
}

.repetance-btn2 {
  display: none;
}
.regret-button {
  width: 100%;
  max-height: 44px;
}

@media only screen and (max-width: 800px) {
  .repetance-btn1 {
    display: none !important;
  }
  .repetance-btn2 {
    display: flex;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
@media only screen and (max-width: 400px) {
  .regret-button {
    .v-btn__content {
      white-space: normal;
      word-break: break-word;
      width: 100%;
      font-size: 12px;
    }
  }
}

* {
  padding: 0;
  margin: 0;
}
.wrapper {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 8px;
  background-color: transparent;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.social-box {
  padding: 16px;
}
@media only screen and (max-width: 360px) {
  .social-row {
    padding-top: 0px !important;
    margin-top: -10px !important;
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>
